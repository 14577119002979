(function ($, openLogin) {
    'use strict';
    var EMAIL_REGEX = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

    //csrf
    $.ajaxSetup({headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}});

    //auth
    $(document).ready(function () {
        if (openLogin) {
            $('.accountActivated').removeClass('hide').html(openLogin);

            showLogin();

            $('#login-overlay').modal('show');
        }

        $('.dont-submit').submit(function () {
            return false;
        });


        $(document).on('click', '.userLogin', function () {
            showLoginModal();
            return false;
        });


        $(document).on('click', '.userRegister', function (e) {
            e.preventDefault();
            showRegister();
            var $this = $(this);
            var $errorLabel = $this.data('label');
            if ($errorLabel) {
                $errorLabel.hide();
            }
            if ($this.data('input')) {
                var email = $($this.data('input')).val();
                //client wanted this.
                var valid = EMAIL_REGEX.test(email);

                if (valid) {
                    $('#username,#register-email').val(email);
                } else {
                    if (!$errorLabel) {
                        $errorLabel = $('<p class="text-danger">Invalid Email Specified</p>')
                            .appendTo($this.closest('form'));
                        $this.data('label', $errorLabel);
                    }
                    $errorLabel.show();
                    return;
                }

            }
            $('#login-overlay').modal('show');
        });

        window.showLoginModal = function () {
            showLogin();
            $('#login-overlay').modal('show');
        };

        function showLogin() {
            $('.loginWrapper').removeClass('hide');
            $('.registerWrapper').addClass('hide');
        }

        function showRegister() {
            $('.registerWrapper').removeClass('hide');
            $('.loginWrapper').addClass('hide');
        }

        $.validator.setDefaults({errorClass: 'text-danger'});

        $('.form-validate').each(function () {
            $(this).validate();
        });

        $('#account-register-form').validate({
            messages: {
                password_confirmation: {
                    equalTo: 'Password and confirm password don\'t match'
                },
                terms: {
                    required: 'You must accept the Terms of Use and Privacy Statement'
                }
            },
            errorPlacement: function (error, element) {
                var placement = $(element).data('error');
                if (placement) {
                    $(placement).append(error)
                } else {
                    error.insertAfter(element);
                }
            }
        })
    });

    //page ajax requests
    (function () {
        //nav tabs
        $('.nav-tabs > li > a').click(function (e) {
            e.preventDefault();
            var $this = $(this);

            var $target = $($this.data('target'));
            $this.parent().siblings('.active').removeClass('active');
            $this.parent().addClass('active');
            $target.siblings('.mdl-layout__tab-panel').removeClass('is-active');
            $target.addClass('is-active');
        });

        $('.nav-tabs.switch-links > li > a').click(function (e) {
            e.preventDefault();
            changeUrl(this.href);
        });

        $('.nav-tabs.ajax-tabs > li >a ').click(function (e) {
            var $this = $(this);
            e.preventDefault();
            if ($this.data('loaded')) {
                return;
            }
            var $target = $($this.data('target'));

            $this.button('loading');
            $.get($this.attr('href'))
                .done(function (html) {
                    $target.find('.page-content').html(html);
                    $this.data('loaded', true);
                }).fail(function () {
                    $target.find('.page-content').html('<p class="alert alert-danger">Error Loading Page</p>');
                })
                .always(function () {
                    $this.button('reset');
                });
        });


        //page search
        $('.search-box').on('input', function () {
            var $this = $(this);
            searchFilter($this.val(), $this.data('target'));
        });

        $('.search-button').click(function (e) {
            e.preventDefault();
            $(this).closest('form').find('.search-box').trigger('input');
        });


        function searchFilter(term, area) {
            var rows = $(area).find('.search-row');
            if (term == '') {
                rows.removeClass('hidden');
                return;
            }

            rows.each(function () {
                var $this = $(this);
                if ($this.text().toLowerCase().indexOf(term.toLowerCase()) == -1) {
                    $this.addClass('hidden');
                } else {
                    $this.removeClass('hidden');
                }
            });
        }
    }());


    /**
     * Change url without reloading browser
     * @param url
     * @returns {bool}
     */
    window.changeUrl = function (url) {
        if (window.history && window.history.pushState) {
            window.history.pushState("", document.title, url);
        }
        return window.history && window.history.pushState;
    };

    //back button pressed
    $(window).on('popstate', function () {
        var url = window.location.href;
        if (url.indexOf('#section-') > -1) {
            return true;
        }

        showLoading();
        $.ajax({
                url: url,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-Requested-With', {
                        toString: function () {
                            return '';
                        }
                    });
                }
            })
            .done(function (resp) {
                var doc = document.open("text/html", "replace");
                doc.write(resp);
                doc.close();
            })
            .fail(function () {
                showMessage('danger', 'Error Loading page');
            })
            .always(function () {
                hideLoading();
            });
    });


//fix facebook ugly hash after login
    if (window.location.hash == '#_=_') {
        if (window.history && window.history.replaceState) {
            window.history.replaceState('', document.title, window.location.pathname);
        } else {
            // Prevent scrolling by storing the page's current scroll offset
            var scroll = {
                top: document.body.scrollTop,
                left: document.body.scrollLeft
            };
            window.location.hash = '';
            // Restore the scroll offset, should be flicker free
            document.body.scrollTop = scroll.top;
            document.body.scrollLeft = scroll.left;
        }
    }

    //remove ugly # from google login
    var index = window.location.href.indexOf('#');
    if (index == window.location.href.length - 1) {
        if (window.history && window.history.replaceState) {
            window.history.replaceState('', document.title, window.location.pathname);
        }
    }


    var SCROLL_OFFSET = 350;
    var PAGINATION_CLASS = '.pagination:visible';
    $(PAGINATION_CLASS).last().attr('data-appear-top-offset', SCROLL_OFFSET);

    //automatic next page
    $('.mdl-layout__content').scroll(function () {
        var $lastPagination = $(PAGINATION_CLASS).last();
        if ($lastPagination.is(':appeared') && !$lastPagination.parent().hasClass('ignore')) {
            handleFetchNextPage($lastPagination);
        }
    });


    function handleFetchNextPage($pagination) {
        var nextUrl = $pagination.find('.active').next().find('a').attr('href');
        if (!nextUrl) {
            return;
        }
        $pagination.hide();
        var $loader = $('<p class="text-center"><i class="fa fa-spinner fa-2x fa-spin"></i><br />Loading</p>')
            .insertAfter($pagination);

        //perform ajax request for next page
        $.get(nextUrl)
            .done(function (html) {
                var target = $pagination.parent().data('target');
                if (target) {
                    var $html = $(html.html);
                    $html.appendTo($(target));
                    $pagination.replaceWith($(html.pagination));
                    if (html.paginationDetails) {
                        updatePaginationDetails($(html.paginationDetails).text());
                    }
                } else {
                    var $html = $(html);
                    var $newPageDetails = $html.filter('.pagination-details');
                    if ($newPageDetails.length) {
                        updatePaginationDetails($newPageDetails.html());
                        $newPageDetails.remove();
                    }
                    $html.insertAfter($pagination);
                    $pagination.remove();
                }
                changeUrl(nextUrl);

                cleanup();
            })
            .fail(function () {
                $pagination.show();
                var $error = $('<p class="alert alert-danger text-center">Error loading next page</p>')
                    .insertAfter($pagination);
                window.setTimeout(function () {
                    $error.remove();
                }, 10000);
            })
            .always(function () {
                $loader.remove();
            });

        function updatePaginationDetails(newHtml) {

            var $old = $('.pagination-details'),
                oldHtml = $old.html();

            var start = oldHtml.indexOf('-'),
                end = oldHtml.indexOf(' of');

            $old.html(oldHtml.substr(0, start + 1) + extractNewTo() + oldHtml.substr(end));


            function extractNewTo() {
                var start = newHtml.indexOf('-'),
                    end = newHtml.indexOf(' of');

                return newHtml.substring(start + 1, end);
            }
        }

        function cleanup() {
            //sync paginations
            var $all = $(PAGINATION_CLASS);
            $all.each(function (index, ele) {
                if (ele != $all.last()[0]) {
                    var $ele = $(ele);
                    $ele.html($all.last().html());
                }
            });
            $all.last().attr('data-appear-top-offset', SCROLL_OFFSET);

            //sync card & list view buttons
            var PAGE_SEARCH = 'page=';
            var page = nextUrl.substr(nextUrl.indexOf(PAGE_SEARCH));
            var $wrapper = $('.change-view a')
                .each(function () {
                    this.href = this.href.substr(0, this.href.indexOf(PAGE_SEARCH)) + page;
                });
        }
    }

    //populate input url with http://
    $('.input-url').focusin(function () {
        if (this.value == '') {
            this.value = 'http://';
        }
    }).focusout(function () {
        if (this.value == 'http://') {
            this.value = '';
        }
    });

    $('body').delegate('.social-share a', 'click', function (e) {
            e.preventDefault();
            window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        })
        .delegate('.random-quote', 'click', function (e) {
            var LOADING_CLASS = 'fa-spin';
            e.preventDefault();
            var $this = $(this);

            if ($this.find('.fa').hasClass(LOADING_CLASS)) {
                return false;
            }
            $this.find('.fa').addClass(LOADING_CLASS);

            $.get(this.href)
                .done(function (html) {
                    var prevQuote = $this.closest('.single-quote');
                    $(html).insertAfter(prevQuote);
                    prevQuote.remove();
                }).fail(function () {
                $this.find('.fa').removeClass(LOADING_CLASS);
                showMessage('error', 'error loading next random quote');
            });
        });

    window.showMessage = function (alertType, message, isHtml) {
        var type = alertType == 'danger' ? 'error' : alertType;
        var title = type == 'error' ? 'Error:' : 'Good Job!';
        if (void 0 === isHtml) {
            isHtml = false;
        }
        swal({
            title: title,
            text: message,
            type: type,
            timer: 10000,
            html: isHtml
        })
    };

    window.createLink = function (relativePath) {
        return $('meta[name=ROOT_URL]').attr('content') + relativePath;
    };

    /**
     * Typeahead
     */
    var url = createLink('/search');

    function filterResults(result) {
        return result.data ? result.data : result;
    }

    //sources
    var topics = new Bloodhound({
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: url + '/topics?s=%QUERY',
            wildcard: '%QUERY',
            filter: filterResults
        }
    });

    var keywords = new Bloodhound({
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: url + '/keywords?s=%QUERY',
            wildcard: '%QUERY',
            filter: filterResults
        }
    });

    var authors = new Bloodhound({
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: url + '/authors?s=%QUERY',
            wildcard: '%QUERY',
            filter: filterResults
        }
    });

    $('.page-search').each(function () {
        initTypeAhead(this);
    });


    var $searchField = $('#search-field').on('input', function () {
        var $this = $(this);
        if (!$this.data('initialized')) {
            $this.data('initialized', true);
            initTypeAhead(this);
            $this[0].focus();
        }
    }).keydown(function (e) {
        if (e.keyCode == 13) {
            $(this).closest('form')[0].submit();
        }
    });

    function initTypeAhead(element) {
        $(element).typeahead({
            minLength: 2,
            highlight: true
        }, {
            name: 'topics',
            source: topics.ttAdapter(),
            displayKey: 'topic'
        }, {
            name: 'keywords',
            source: keywords,
            displayKey: 'keyword'
        }, {
            name: 'authors',
            source: authors,
            displayKey: 'full_name'
        }).bind('typeahead:select', function () {
            $(this).closest('form')[0].submit();
        });
    }
}(jQuery, window.openLogin));

(function ($) {
    //collect modal
    var shown = false;
    var $modal = $('#quote-collect-modal');
    $('body').delegate('.collect', 'click', function (e) {
        e.preventDefault();
        if (!$('meta[name=USER_ID]').attr('content')) {
            showLoginModal();
            return false;
        }
        $modal.find('.alert').hide();
        $modal.modal('show');
        $modal.find('input[name=quote_id]').val($(this).data('id'));
        if (!$modal.data('init')) {
            initModal($modal);
            $modal.data('init', true);
        }
    }).delegate('.modal', 'hidden.bs.modal', function () {
        shown = false;
    }).delegate('.modal', 'shown.bs.modal', function () {
        shown = true;
    });
    //.delegate('.favorite-quote', 'mouseenter', toggleHeart)
    //.delegate('.favorite-quote', 'mouseleave', toggleHeart);

    function toggleHeart() {
        var $this = $(this);
        if (!$this.hasClass('text-love')) {
            $this.find('.fa').toggleClass('fa-heart fa-heart-o');
        }

    }

    $(document).keyup(function (e) {
        if (e.keyCode == 27 && shown) {
            var $modal = $('.modal:visible');
            if ($modal.data('keyboard') === undefined || $modal.data('keyboard') === true) {
                $modal.modal('hide');
            }
        }
    });

    //close notifications
    var $alerts = $('#flash-messages').find('.alert');
    window.setTimeout(function () {
        $alerts.slideUp();
    }, 5000);

    function initModal($modal) {
        $modal.find('form').each(function () {
            var $loader;
            $(this).validate({
                errorClass: 'text-danger',
                submitHandler: function (form) {
                    var $form = $(form);
                    var data = {};
                    $form.find('*[name]').each(function () {
                        data[this.name] = this.value;
                    });
                    if (!$loader) {
                        $loader = $('<p class="text-center loader"><i class="fa fa-spin fa-2x fa-spinner"></i><br />Loading...</p>')
                            .prependTo($form.find('.form-group').last());
                    } else {
                        $loader.show();
                    }

                    //console.log(data);
                    $.post($form.attr('action'), data)
                        .done(function (resp) {
                            $form[0].reset();
                            var $existingTags = $('.tags');
                            var tagId = resp.tag.id;
                            var isNew = true;
                            $existingTags.find('option')
                                .each(function () {
                                    if (tagId == this.value) {
                                        isNew = false;
                                        return false;
                                    }
                                });

                            $modal.modal('hide');
                            var link = '<a href="' + createLink('/account/collections/' + resp.tag.id) + '">' + resp.tag.title + '</a>';
                            if (isNew) {
                                $('<option value="' + tagId + '">' + link + '</option>')
                                    .appendTo($existingTags);
                                showMessage('success', "Collection '" + link + "' has been created and this quote has been added to the collection", true);
                            } else {
                                showMessage('success', "This quote has been added to the '" + link + "' collection", true);
                            }

                        })
                        .fail(function (error) {
                            try {
                                var resp = $.parseJSON(error.responseText);
                                var messages = $.map(resp, function (val) {
                                    return val.join(' ');
                                });

                                showMessage('danger', messages.join(' '));
                            } catch (e) {
                                showMessage('danger', "Error saving collection");
                            }

                        })
                        .always(function () {
                            $loader.hide();
                        });
                }
            });
        });

        $modal.on('hidden.bs.modal', function () {
            if ($modal.find('.tags option').length > 1) {
                $(this).find('.carousel').find('.item')
                    .removeClass('active')
                    .first().addClass('active');
            } else {
                $(this).find('.carousel').find('.item')
                    .removeClass('active')
                    .last().addClass('active');
            }

            //clear error messages
            $modal.find('form').each(function () {
                $(this).data('validator').resetForm();
            });
        });
    }
}(jQuery));

//load pagination
(function ($) {
    $('body').delegate('.pagination a', 'click', function (e) {
        e.preventDefault();
        var $this = $(this);
        showLoading();
        $.get(this.href)
            .done(function (data) {
                updatePaginationDetails(data);
                var $content, $pagination;
                var normal = true;
                if (data.pagination) {
                    $pagination = $(data.pagination);
                    normal = false;
                } else {
                    $content = $(data);
                    $pagination = $content.filter('.pagination');
                }


                var $wrapper = $this.closest('.pagination').parent();
                if ($wrapper.children().length == 1) {
                    $wrapper = $wrapper.parent();
                }

                if (normal) {
                    if ($wrapper.hasClass('section')) {
                        $wrapper = $wrapper.parent();
                    }

                    var $section = $wrapper.find('.section');
                    if ($section.length !== 1) {
                        $wrapper = $('.page-content > .container-fluid > .row > .col-sm-9');
                        $section = $wrapper.find('.section');
                        //remove duplicates
                        var excess = $section.length - 1;
                        for (var i = 0; i < excess; i++) {
                            $($section.get(i)).remove();
                        }
                        $content = $content.filter('.section').children();
                    }
                    $section.children().remove();
                    $wrapper.find('.pagination:visible').html($pagination.html());

                    $section.append($content);
                } else {
                    var $target = $($('.data-target').data('target'))
                        .html(data.html);
                    //console.log($target.html());
                    $wrapper.find('.pagination:visible').html($pagination.html());
                }

                changeUrl($this.attr('href'));
            })
            .fail(function () {
                showMessage('danger', 'Error Loading content', '#flash-messages')
            })
            .always(function () {
                hideLoading();
            });
    });

    /**
     * pagination details i.e showing 1-50 of 700 quotes
     * @param resp
     */
    function updatePaginationDetails(resp) {
        var $newPaginationDetails;
        if (resp.pagination) {
            $newPaginationDetails = $(resp.paginationDetails);
        } else {
            $newPaginationDetails = $(resp).filter('.pagination-details');
            $newPaginationDetails.remove();
        }

        if ($newPaginationDetails.length) {
            $('.pagination-details').html($newPaginationDetails.html());
        }
    }

    var $modal = $('#load-modal');

    window.showLoading = function () {
        $modal.modal('show');
    };

    window.hideLoading = function () {
        $modal.modal('hide');
    }
}(jQuery));


//suggest an edit
(function ($) {
    $('.suggest-edit-form').each(function () {
        initValidation($(this));
    });

    $('body').delegate('.load-modal', 'click', function (e) {
        e.preventDefault();
        var $btn = $(this);
        var targetModal = $btn.data('target');
        if (!tryGenerate(targetModal, $btn)) {
            loadShowModal(targetModal, $btn.attr('href'), $btn);
        }
    });

    function tryGenerate(target, btn) {
        var parts = target.split('-'),
            id = parts.pop(),
            modal = parts.join('-'),
            $modal = $(modal);

        if (modal == '#cite-source-modal') {
            var $wrapper = $(btn).closest('.quote-view');
            if ($wrapper.length) {
                $modal.find('.author').text($wrapper.find('.author').text());
                var $link = $wrapper.find('.url');
                if ($link.attr('href')) {
                    $modal.find('.url').text($link.attr('href'));
                } else {
                    $modal.find('.url').text($link.text());
                }
                $modal.modal('show');
            }
            return $wrapper.length;
        } else if (modal == '#suggest-edit-modal') {
            var $form = $modal.find('form'),
                url = $form.data('action');
            $form.attr('action', url.substr(0, url.lastIndexOf('0')) + id);
            $modal.modal('show');
            return true;
        }

        return false;
    }


    function loadShowModal(id, url, btn) {
        var $modal = $(id),
            $btn = $(btn);
        if (!$modal.length) {
            if ($btn.find('.fa').hasClass('fa-spin')) {
                return false;
            }

            var prevClass = $btn.find('.fa').attr('class');
            $btn.find('.fa').attr('class', 'fa fa-2x fa-spin fa-spinner');
            $.get(url)
                .done(function (html) {
                    $modal = $(html)
                        .appendTo('body');
                    $modal.modal('show');
                    if ($modal.find('.suggest-edit-form').length) {
                        initValidation($modal.find('.suggest-edit-form'));
                    }
                })
                .fail(function () {
                    showMessage('danger', 'Error Loading content');
                })
                .always(function () {
                    $btn.find('.fa').attr('class', prevClass);
                })
        }

        $modal.modal('show');
    }

    function initValidation($editForm) {
        $editForm.validate({
            errorClass: 'text-danger',
            submitHandler: function () {
                var data = {};
                $editForm.find('*[name]').each(function () {
                    data[this.name] = this.value;
                });
                var $btn = $editForm.find('button');
                $btn.button('loading');

                //console.log(data);
                $.post($editForm.attr('action'), data)
                    .done(function (resp) {
                        var $modal = $editForm.closest('.modal');
                        $editForm[0].reset();
                        $modal.modal('hide');
                        showMessage('success', 'Your suggestion has been sent!');
                    })
                    .fail(function (error) {
                        try {
                            var resp = $.parseJSON(error.responseText);
                            console.log(resp);
                            var messages = $.map(resp, function (val) {
                                return val.join(' ');
                            });

                            showMessage('danger', messages.join(' '));
                        } catch (e) {
                            showMessage('danger', "There was a problem saving your submission");
                        }

                    })
                    .always(function () {
                        $btn.button('reset');
                    });
            }
        });
    }
}(jQuery));

$(document).ready(function () {
    var submitIcon = $('.searchbox-icon');
    var inputBox = $('.searchbox-input');
    var searchBox = $('.searchbox');
    var isOpen = false;
    submitIcon.click(function () {
        submitIcon.find('.fa').toggleClass('fa-search fa-close');
        inputBox.val('');
        if (isOpen == false) {
            searchBox.addClass('searchbox-open')
                .parent().addClass('is-focused');
            inputBox.focus();
            isOpen = true;
        } else {
            searchBox.removeClass('searchbox-open')
                .parent().removeClass('is-focused');
            inputBox.focusout();
            isOpen = false;
        }
    });
    submitIcon.mouseup(function () {
        return false;
    });
    searchBox.mouseup(function () {
        return false;
    });
    $(document).mouseup(function () {
        if (isOpen == true) {
            $('.searchbox-icon').css('display', 'block');
            submitIcon.click();
        }
    });
});

(function ($) {
    var userId = $('meta[name=USER_ID]').attr('content');
    if (!userId) {
        return;
    }

    $('body').delegate('.favorite-quote', 'click', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.hasClass('text-love')) {
            //remove favorite
            swal({
                title: 'Remove From Favorites',
                text: 'Are you sure you want to remove this quote from favorites?',
                showCancelButton: true,
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, removeFavorite);

        } else {
            addFavorite();
        }


        function addFavorite() {
            var $fa = $this.find('.fa');
            if ($fa.hasClass('fa-spin')) {
                return;
            }

            $fa.toggleClass('fa-heart-o fa-spin fa-spinner');
            $.get($this.attr('href'))
                .done(function (resp) {
                    $this.addClass('text-love')
                        .attr('href', resp);
                    $fa.toggleClass('heartbeat fa-heart fa-spin fa-spinner');
                    $this.find('span.small').text('Saved to Favorites');

                    //
                    simpleAlert('success',
                        'Quote added to <a href="' + createLink('/account/favorites') + '" class="alert-link">Your Favorites</a>');
                })
                .fail(function (error) {
                    $fa.toggleClass('fa-heart-o  fa-spin fa-spinner');
                    showMessage('error', 'Error adding quote to favorites');
                });
        }

        function removeFavorite() {
            $.get($this.attr('href'))
                .done(function (resp) {
                    $this.toggleClass('text-love')
                        .attr('href', resp)
                        .find('.fa').toggleClass('fa-heart fa-heart-o heartbeat');
                    $this.find('span.small').text('Save to Favorites');

                    //showMessage('success', 'Quote successfully removed from favorites');
                    swal.close();
                })
                .fail(function () {
                    showMessage('error', 'Error removing quote from favorites');
                })
        }

        window.simpleAlert = function (type, message, closeDelay) {
            var $alert = $('<p class="alert alert-dismissable alert-' + type + '"><button data-dismiss="alert" type="button" class="close">&times;</button>'
                + message + '</p>')
                .hide()
                .appendTo('#flash-messages > div')
                .slideDown();

            if (void 0 == closeDelay) {
                closeDelay = 10000;
            }

            window.setTimeout(function () {
                $alert.slideUp(void 0, function () {
                    $alert.remove();
                });
            }, closeDelay);
        }
    });
}(jQuery));

(function ($) {
    var $text = $('#feedback-text');
    $('#feedback-reason').change(function () {
            if (this.value.trim().length) {
                $text.slideDown();
            } else {
                $text.slideUp();
            }
        })
        .trigger('change');
}(jQuery));

//detect user timezone
$(document).ready(function () {
    if (window.jstz) {
        var tz = jstz.determine();
        $.post('/set-timezone', {timezone: tz.name()})
            .done(function (resp) {
                console.log(resp);
            });
    }
});
